<template>
    <section class="d-flex flex-column overflow-auto pt-3" style="height:100%">
        <div class="col-auto px-4 py-3 text-general bg-white f-18 f-600">
            Registro de las comisiones pagadas
        </div>
        <el-table
        :data="dataTable"
        header-row-class-name="text-general f-16"
        height="250" style="width: 100%"
        @row-click="openDetalle"
        >
            <el-table-column label="Fecha del pago" align="center" min-width="150">
                <template slot-scope="{row}">
                    <p class="text-general text-center">{{ row.created_at | helper-fecha('DD MMM Y, h:mm a') }}</p>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="cedis_nombre" label="Cedis" align="center" min-width="150" /> -->
            <el-table-column label="Valor pagado" align="center" min-width="150">
                <template slot-scope="{row}">
                    <p class="text-general text-center">{{ convertMoney(row.valor_pagado,row.idm_moneda) }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="cant_pedidos" label="Pedidos pagados" align="center" min-width="150" />
            <el-table-column prop="encargado" label="Encargado" align="center" min-width="150" />
            <el-table-column label="" align="center" min-width="150">
                <template slot-scope="{row}">
                    <el-tooltip class="item" effect="light" :content="row.comentario" placement="bottom">
                        <div class="btn-action border d-middle-center cr-pointer text-general">
                            <i class="icon-message-text-outline f-14" />
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="paginar-general d-middle-center" layout="prev, pager, next" :total="paginate.total" :page-size="paginate.per_page" @current-change="getTabla" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import VueExcelXlsx from "vue-excel-xlsx";
import Comisiones from '~/services/comisiones/comisiones_pagadas';

Vue.use(VueExcelXlsx)

export default {
    name: 'ComisionesPagadasLlantas',
    components: {
    },
    data(){
        return{
            dataTable: [],
            registros: [],
            page: 0,
            paginate: {}
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        id_cedis(val){
            // this.getTabla()
        }
    },
    mounted(){
        // if(this.id_cedis != null){
        this.getTabla(1)
        // }
    },
    methods: {
        async getTabla(page){
            try {
                const { data } = await Comisiones.getComisiones({page})
                this.dataTable = data.data.data
                this.paginate = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        openDetalle({id}){
            this.$router.push({name: 'admin.tesoreria.comisiones-pagadas.detalle', params: { id }})
        },
        prueba(){
            console.log(998);
        }
    }
}
</script>
<style scoped>
.boton-excel {
    border: 0px;
    background: transparent;
    color: #fff;
}
</style>
